/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"1vxxjbff4ua"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Photogallery"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--40" name={"ks4ubm4ptar"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=1400x_.jpg 1400w"} content={null} position={{"x":"-0.21929824561403508%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=1400x_.jpg 1400w"} content={null} position={{"x":"-0.21929824561403508%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"footer"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/rsvp"} content={"RSVP"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/gift-registry"} content={"Gift registry"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/photogallery"} content={"Photogallery"}>
              </Button>

              <Image className="mt--40" alt={""} src={"https://cdn.swbpg.com/o/11212/0cc2932258564e9dadfbd3f8a0b406da.svg"} svg={false} href={"https://facebook.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Image className="mt--20" alt={""} src={"https://cdn.swbpg.com/o/11212/c052f05d66c6491c98f55819ed14c078.svg"} svg={false} href={"https://instagram.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"We look forward to seeing you!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}